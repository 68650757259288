<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastro" width="80%">
      <div slot="title">Inserir Nova Chamada de Vídeo</div>

      <div class="" slot="content">
        <ed-form :formData="formData" :handlerSave="salvar" disableFixedSave>
          <div class="row">
            <div class="col-12 text-left">
              <ed-alert type="warning">
                <b>Atenção</b>
                <p>
                  Após o cadastro da chamada de vídeo será disponibilizado um
                  link para acessar a sala de vídeo. Esse link é válido por 24h,
                  após esse tempo o link será inativado e o acesso a sala será
                  bloqueado.
                </p>
              </ed-alert>
            </div>
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      strModel="Consulta/ConsultaVideo"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      :pagination="objPagination"
      :filter="formFiltros"
      @register="onRegister"
      @delete="onDelete"
      @search="getVideo"
      disableEditRows
      :config="{ registerName: 'Inserir Nova Chamada de Vídeo' }"
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdAlert,
  EdModal,
  EdInputText,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    consulta: {
      type: [Object],
      required: true,
      default: null,
    },
  },
  components: {
    EdForm,
    EdTable,
    EdAlert,
    EdModal,
    EdInputText,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastro: false,
      arrayHeaders: [
        {
          text: "Link da Sala",
          sortable: true,
          value: "strIdentificador",
        },
        {
          text: "Usuários Conectados",
          sortable: true,
          value: "strUsuarioConectado",
        },
        {
          text: "Status",
          sortable: true,
          value: "strTipoStatus",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {
        intCadastroGeralEmpresaId: null,
        intFuncionarioId: null,
      },
      formData: {
        intId: 0,
        intConsultaId: this.consulta.intId,
        strIdentificador: null,
      },
    };
  },
  watch: {},
  methods: {
    initialize() {
      this.getVideo();
    },

    onRegister() {
      this.boolExibirCadastro = !this.boolExibirCadastro;

      this.formData = {
        intId: 0,
        intConsultaId: this.consulta.intId,
      };
    },

    onEdit(data) {
      this.formData = _.cloneDeep(data);
      this.boolExibirCadastro = !this.boolExibirCadastro;
    },

    onDelete(data) {},

    getVideo() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Consulta/ConsultaVideo", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {
              let item = {
                intId: objModel.intId,
                strIdentificador:this.$root.$session.cliente.strUrlSistema+'video/'+ objModel.strIdentificador,
                strUsuarioConectado:objModel.sessoes.length+' usuário(s)',
                strTipoStatus:'<span style="color:'+objModel.tipo_status.strValor.strCor+'"><i class="'+objModel.tipo_status.strValor.strIcone+'"/> '+objModel.tipo_status.strNome+'</span>',
                _item: objModel,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Consulta/ConsultaVideo", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.getVideo();
            this.boolExibirCadastro = !this.boolExibirCadastro;
          }
        });
    },
  },
};
</script>
