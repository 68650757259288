<template>
  <div>
    <div
      class="row ma-0 pa-0"
      v-if="this.formData.audiometrias && this.formData.audiometrias.length"
    >
      <ed-modal v-model="boolExibirCadastroLimiares" width="30%">
        <div slot="title">Limiares Aéreos :: Novo Item</div>

        <div class="" slot="content">
          <ed-form :formData="formDataLimiares" :handlerSave="salvar" disableFixedSave>
            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0"
              v-model="formDataLimiares.strNome"
              name="strNome"
              label="Nome"
              type="number"
              rules="required"
            />
          </ed-form>
        </div>
      </ed-modal>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Audiometria</span>

        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0"
            v-model="formData.audiometrias[0].intAudiometroId"
            name="intAudiometroId"
            item-text="strNome"
            label="Audiometro"
            route="Audiometria/Audiometro"
            :itemList="
              formData.audiometrias[0].audiometro
                ? [formData.audiometrias[0].audiometro]
                : []
            "
          />
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="formData.audiometrias[0].intParametroMeatoscopiaOrelhaDireta"
            name="intParametroMeatoscopiaOrelhaDireta"
            :items="bind.arrayMeatoscopiaOrelha"
            label="Meato
            scopia da Orelha Direita"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="formData.audiometrias[0].intParametroMeatoscopiaOrelhaEsquerda"
            name="intParametroMeatoscopiaOrelhaEsquerda"
            :items="bind.arrayMeatoscopiaOrelha"
            label="Meatoscopia da Orelha Esquerda"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="formData.audiometrias[0].boolAfereLimiaresAeareo"
            name="boolAfereLimiaresAeareo"
            :items="[
              { intId: 1, strNome: 'SIM' },
              { intId: 0, strNome: 'NÃO' },
            ]"
            label="Afere Limiares Aéreos"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="formData.audiometrias[0].boolAfereLimiaresOsseo"
            name="boolAfereLimiaresOsseo"
            :items="[
              { intId: 1, strNome: 'SIM' },
              { intId: 0, strNome: 'NÃO' },
            ]"
            label="Afere Limiares Ósseos"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0"
            v-model="formData.audiometrias[0].intParametroRuidoMascaramento"
            name="intParametroRuidoMascaramento"
            :items="bind.arrayRuidoMascaramento"
            label="Ruído de Mascaramento"
          />
        </div>

        <span class="form-title">Limiares Orelha Direita</span>


        <div class="row">
          <p class="col-12 mb-0 pb-0"><b>Via Aérea</b></p>
          <p class="col-12 ma-0 pl-0">
            <ed-button
              label="Adicionar novo item"
              text
              color="success"
              :iconLeft="$utilidade.getIcone('cadastrar')"
              @click="exibirCadastroLimiar('strLimiaresAereoOrelhaDireita')"
            />
          </p>
          <ed-input-money
            v-for="(objItem, index) in formData.audiometrias[0].strLimiaresAereoOrelhaDireita"
            :key="index"
            class="col-3 pt-0 mt-0 mb-0 pb-0"
            name="floatValor"
            v-model="objItem.floatValor"
            :items="bind.arrayRuidoMascaramento"
            :label="objItem.strNome"
          />
        </div>

        <div class="row">
          <p class="col-12 mb-0 pb-0"><b>Via Óssea</b></p>
          <p class="col-12 ma-0 pl-0">
            <ed-button
              label="Adicionar novo item"
              text
              color="success"
              :iconLeft="$utilidade.getIcone('cadastrar')"
              @click="exibirCadastroLimiar('strLimiaresOsseoOrelhaDireita')"
            />
          </p>
          <ed-input-money
            v-for="(objItem, index) in formData.audiometrias[0].strLimiaresOsseoOrelhaDireita"
            :key="index"
            class="col-3 pt-0 mt-0 mb-0 pb-0"
            name="floatValor"
            v-model="objItem.floatValor"
            :items="bind.arrayRuidoMascaramento"
            :label="objItem.strNome"
          />
        </div>

        <span class="form-title">Limiares Orelha Esquerda</span>

        <div class="row">
          <p class="col-12 mb-0 pb-0"><b>Via Aéreo</b></p>

          <p class="col-12 ma-0 pl-0">
            <ed-button
              label="Adicionar novo item"
              text
              color="success"
              :iconLeft="$utilidade.getIcone('cadastrar')"
              @click="exibirCadastroLimiar('strLimiaresAereoOrelhaEsquerda')"
            />
          </p>
          <ed-input-money
            v-for="(objItem, index) in formData.audiometrias[0].strLimiaresAereoOrelhaEsquerda"
            :key="index"
            class="col-3 pt-0 mt-0 mb-0 pb-0"
            name="floatValor"
            v-model="objItem.floatValor"
            :items="bind.arrayRuidoMascaramento"
            :label="objItem.strNome"
          />
        </div>

        <div class="row">
          <p class="col-12 mb-0 pb-0"><b>Via Óssea</b></p>

          <p class="col-12 ma-0 pl-0">
            <ed-button
              label="Adicionar novo item"
              text
              color="success"
              :iconLeft="$utilidade.getIcone('cadastrar')"
              @click="exibirCadastroLimiar('strLimiaresOsseoOrelhaEsquerda')"
            />
          </p>
          <ed-input-money
            v-for="(objItem, index) in formData.audiometrias[0].strLimiaresOsseoOrelhaEsquerda"
            :key="index"
            class="col-3 pt-0 mt-0 mb-0 pb-0"
            name="floatValor"
            v-model="objItem.floatValor"
            :items="bind.arrayRuidoMascaramento"
            :label="objItem.strNome"
          />
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Interpretação Orelha Diretita</span>
        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="formData.audiometrias[0].intParametroDiagnosticoClinicoOrelhaDireita"
            name="intParametroDiagnosticoClinicoOrelhaDireita"
            :items="bind.arrayDiagnosticoClinico"
            label="Diagnóstico Clínico"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="
              formData.audiometrias[0].intParametroDiagnosticoOcupacionalOrelhaDireita
            "
            name="intParametroDiagnosticoOcupacionalOrelhaDireita"
            :items="bind.arrayDiagnosticoOcupacional"
            label="Diagnóstico Ocupacional"
          />
        </div>

        <span class="form-title">Interpretação Orelha Esquerda</span>
        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="
              formData.audiometrias[0].intParametroDiagnosticoClinicoOrelhaEsquerda
            "
            name="intParametroDiagnosticoClinicoOrelhaEsquerda"
            :items="bind.arrayDiagnosticoClinico"
            label="Diagnóstico Clínico"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-0"
            v-model="
              formData.audiometrias[0].intParametroDiagnosticoOcupacionalOrelhaEsquerda
            "
            name="intParametroDiagnosticoOcupacionalOrelhaEsquerda"
            :items="bind.arrayDiagnosticoOcupacional"
            label="Diagnóstico Ocupacional"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdModal,
  EdForm,
  EdInputText,
  EdButton,
  EdInputMoney,
  EdInputAutoComplete,
  EdInputSelect,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdModal,
    EdForm,
    EdInputText,
    EdButton,
    EdInputMoney,
    EdInputAutoComplete,
    EdInputSelect,
  },
  inject: ["bind", "setExame", "setTipoStatus"],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      boolExibirCadastroLimiares: false,
      strNomeLimiarAtivo: null,
      formDataLimiares: {
        intId: 0,
        strNome: null,
      },
    };
  },
  watch: {
    "formData.intId"() {
      this.initialize();
    },
  },
  computed: {},
  methods: {
    exibirCadastroLimiar(strNomeLimiarAtivo) {
      this.boolExibirCadastroLimiares = true;
      this.strNomeLimiarAtivo = strNomeLimiarAtivo;
    },

    initialize() {
      if (!this.formData.audiometrias || !this.formData.audiometrias.length) {
        this.formData.audiometrias = [
          {
            intConsultaExameId: this.formData.intId,
            intAudiometroId: null,
            intParametroMeatoscopiaOrelhaDireta: null,
            intParametroMeatoscopiaOrelhaEsquerda: null,
            intParametroRuidoMascaramento: null,
            intParametroDiagnosticoClinicoOrelhaDireita: null,
            intParametroDiagnosticoClinicoOrelhaEsquerda: null,
            intParametroDiagnosticoOcupacionalOrelhaDireita: null,
            intParametroDiagnosticoOcupacionalOrelhaEsquerda: null,
            strObservacao: null,
            boolAfereLimiaresAeareo: 1,
            boolAfereLimiaresOsseo: 1,
            strLimiaresAereoOrelhaDireita: [
              { intId: 250, strNome: "250 Hz", strValor: 0 },
              { intId: 500, strNome: "500 Hz", strValor: 0 },
              { intId: 1000, strNome: "1000 Hz", strValor: 0 },
              { intId: 2000, strNome: "2000 Hz", strValor: 0 },
              { intId: 3000, strNome: "3000 Hz", strValor: 0 },
              { intId: 4000, strNome: "4000 Hz", strValor: 0 },
              { intId: 5000, strNome: "5000 Hz", strValor: 0 },
              { intId: 6000, strNome: "6000 Hz", strValor: 0 },
              { intId: 8000, strNome: "8000 Hz", strValor: 0 },
            ],
            strLimiaresOsseoOrelhaDireita: [
              { intId: 250, strNome: "250 Hz", strValor: 0 },
              { intId: 500, strNome: "500 Hz", strValor: 0 },
              { intId: 1000, strNome: "1000 Hz", strValor: 0 },
              { intId: 2000, strNome: "2000 Hz", strValor: 0 },
              { intId: 3000, strNome: "3000 Hz", strValor: 0 },
              { intId: 4000, strNome: "4000 Hz", strValor: 0 },
              { intId: 5000, strNome: "5000 Hz", strValor: 0 },
              { intId: 6000, strNome: "6000 Hz", strValor: 0 },
              { intId: 8000, strNome: "8000 Hz", strValor: 0 },
            ],
            strLimiaresAereoOrelhaEsquerda: [
              { intId: 250, strNome: "250 Hz", strValor: 0 },
              { intId: 500, strNome: "500 Hz", strValor: 0 },
              { intId: 1000, strNome: "1000 Hz", strValor: 0 },
              { intId: 2000, strNome: "2000 Hz", strValor: 0 },
              { intId: 3000, strNome: "3000 Hz", strValor: 0 },
              { intId: 4000, strNome: "4000 Hz", strValor: 0 },
              { intId: 5000, strNome: "5000 Hz", strValor: 0 },
              { intId: 6000, strNome: "6000 Hz", strValor: 0 },
              { intId: 8000, strNome: "8000 Hz", strValor: 0 },
            ],
            strLimiaresOsseoOrelhaEsquerda: [
              { intId: 250, strNome: "250 Hz", strValor: 0 },
              { intId: 500, strNome: "500 Hz", strValor: 0 },
              { intId: 1000, strNome: "1000 Hz", strValor: 0 },
              { intId: 2000, strNome: "2000 Hz", strValor: 0 },
              { intId: 3000, strNome: "3000 Hz", strValor: 0 },
              { intId: 4000, strNome: "4000 Hz", strValor: 0 },
              { intId: 5000, strNome: "5000 Hz", strValor: 0 },
              { intId: 6000, strNome: "6000 Hz", strValor: 0 },
              { intId: 8000, strNome: "8000 Hz", strValor: 0 },
            ],
          },
        ];
      }
    },

    salvar() {

      console.log('strNomeLimiarAtivo => '+this.strNomeLimiarAtivo,this.formData.audiometrias)
      if(!this.formData.audiometrias[0][this.strNomeLimiarAtivo]){
        this.formData.audiometrias[0][this.strNomeLimiarAtivo] = []
      }
      
      let index = this.formData.audiometrias[0][this.strNomeLimiarAtivo].findIndex(
        (c) => Number(c.intId) == Number(this.formDataLimiares.strNome)
      );

      if (index != -1) {
        this.$root.$dialog.warning("Esse item já existe");
      } else {
        this.formData.audiometrias[0][this.strNomeLimiarAtivo].push({
          intId: this.formDataLimiares.strNome,
          strNome: this.formDataLimiares.strNome + " Hz",
        });
        this.formData.audiometrias[0][this.strNomeLimiarAtivo].sort(
          (a, b) => a.intId - b.intId
        );

        this.boolExibirCadastroLimiares = false;
        this.strNomeLimiarAtivo = null;
        this.$forceUpdate();
      }
    },
  },
};
</script>
