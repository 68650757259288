<template>
  <div v-if="formData.prontuario && formData.prontuario.length">
    <div class="row text-left mt-3">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <campos-personalizado
          :formData="formData.prontuario[0]"
          :key="'campo-' + $root.$session.versao"
          :intTipoModeloId="1320"
          @salvar="(x) => (formData.prontuario[0].arrayCamposPersonalizado = x)"
        />
      </div>
    </div>

    <div class="row text-left mt-3">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
          >Obervações Gerais</span
        >

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 pr-1"
          v-model="formData.prontuario[0].strObservacao"
          label="Conclusão"
          rules="required"
        />
      </div>
    </div>
     <div class="row text-center mt-3" v-if="formData.prontuario[0].strDataAtualizacao">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
        <div class="col-12 text-center" style="color:red">
          <h5>Última alteração efetuada em <b>{{$utilidade.toDate(formData.prontuario[0].strDataAtualizacao,false)}}</b></h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EdInputTextArea } from "@/components/common/form";
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdInputTextArea,
    CamposPersonalizado,
  },
  inject: ["bind"],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {};
  },
  watch: {
     formData: {
      handler: function (newVal) {
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    initialize() {
    },
  },
};
</script>
