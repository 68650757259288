<template>
  <div>
    <!--EDITAR OUTRAS INFORMAÇÕES EXAME-->
    <ed-modal v-model="boolExibirModalEdit" width="70%">
      <div slot="title">
        <span v-if="formDataExame.intId"
          >{{ formDataExame.exame.strNome }} :: EDITAR</span
        >
        <span v-if="!formDataExame.intId">INSERIR NOVO EXAME</span>
      </div>

      <div class="" slot="content">
        <v-tabs v-model="strTab" centered icons-and-text>
          <v-tab href="#tab-cadastro">
            Cadastro Geral
            <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
          </v-tab>
          <v-tab
            href="#tab-audiometria"
            v-if="
              formDataExame &&
              formDataExame.exame &&
              formDataExame.exame.intTipoCategoriaId == 1407
            "
          >
            Audiometria
            <i :class="$utilidade.getIcone('audiometria') + ' fa-2x'"></i>
          </v-tab>
          <v-tab href="#tab-anexo">
            Anexos
            <i :class="$utilidade.getIcone('upload') + ' fa-2x'"></i>
          </v-tab>
        </v-tabs>

        <ed-form :formData="formDataExame" :handlerSave="salvarExame" disableFixedSave>
          <exame-geral
            :formData="formDataExame"
            v-if="strTab == 'tab-cadastro'"
            :key="'tab-geral-' + $root.$session.versao"
          />
          <exame-anexo
            :formData="formDataExame"
            v-if="strTab == 'tab-anexo'"
            :key="'tab-anexo-' + $root.$session.versao"
          />
          <exame-audiometria
            :formData="formDataExame"
            v-if="strTab == 'tab-audiometria'"
            :key="'tab-audiometria-' + $root.$session.versao"
          />
        </ed-form>
      </div>
    </ed-modal>

    <ed-simple-table
      v-if="formData"
      :config="{ registerName: 'Inserir novo exame' }"
      @register="onRegister"
      disabledSearchRows
    >
      <thead v-if="formData.exames.length">
        <tr>
          <th width="50">#</th>
          <th>Exame</th>
          <th width="180">Data</th>
          <th width="180">Valor</th>
          <th width="180">Resultado</th>
          <th>Parceiro</th>
          <th width="120"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(objConsultaExame, index) in formData.exames">
          <tr :key="index" v-if="!objConsultaExame.aproveitamento">
            <td align="left">{{ index + 1 }}</td>
            <td
              align="left"
              :title="
                objConsultaExame.boolNovoRegistro
                  ? 'Novo exame inserido'
                  : objConsultaExame.status
                  ? 'Exame ' + objConsultaExame.status.strNome
                  : ''
              "
              :style="
                objConsultaExame.boolNovoRegistro
                  ? 'color:green'
                  : objConsultaExame.status
                  ? 'color:' + objConsultaExame.status.strValor.strCor
                  : ''
              "
            >
              <i
                v-if="objConsultaExame.boolNovoRegistro"
                :class="$utilidade.getIcone('novoItem')"
                title="Novo exame inserido"
              />
              <ed-collapse :textValue="objConsultaExame.exame.strNome" :textLimit="30" />
            </td>
            <td align="left">
              <ed-input-date
                class="pl-0 pa-3"
                v-model="objConsultaExame.strDataExame"
                name="strDataExame"
                rules="required"
                hideDetails
                :key="'strDataExame-' + index"
              />
            </td>
            <td align="left">
              <ed-input-money
                class="pl-0 pa-3"
                v-model="objConsultaExame.floatValorVenda"
                name="floatValorVenda"
                hideDetails
                :key="'floatValorVenda-' + index"
              />
            </td>
            <td align="left">
              <ed-input-select
                class="pl-0 pa-3"
                v-model="objConsultaExame.intParametroStatusId"
                name="intParametroStatusId"
                hideDetails
                :key="'intParametroStatusId-' + index"
                :items="bind.arrayStatusExame"
                :iconLeft="
                  objConsultaExame.status
                    ? objConsultaExame.status.strValor.strIcone
                    : null
                "
                @selected="setTipoStatus($event, objConsultaExame)"
              />
            </td>
            <td align="left">
              <ed-input-auto-complete
                class="pl-0 pa-3"
                v-model="objConsultaExame.intCadastroGeralParceiroId"
                name="intCadastroGeralParceiroId"
                item-text="strNome"
                route="Cadastro/CadastroGeral"
                :filters="{
                  intTipoCadastroGeralId: 59,
                }"
                label="Parceiro"
                :initializeItems="false"
                :itemList="objConsultaExame.parceiro ? [objConsultaExame.parceiro] : []"
                hideDetails
                clearable
              />
            </td>
            <td align="left">
              <ed-button
                :iconLeft="$utilidade.getIcone('editar')"
                v-if="objConsultaExame.intId"
                color="primary"
                title="Alterar outras informações do exame"
                icon
                onlyIcon
                @click="onEdit(objConsultaExame, index)"
              />
              <ed-button
                :iconLeft="$utilidade.getIcone('deletar')"
                color="error"
                title="Deletar"
                icon
                onlyIcon
                @click="onDelete(objConsultaExame, index)"
              />
            </td>
          </tr>
        </template>

        <template v-for="(objConsultaExame, index) in formData.exames">
          <tr
            class="ed-consulta-aproveitamento"
            :key="index"
            v-if="objConsultaExame.aproveitamento"
          >
            <td align="left"></td>
            <td align="left">
              <router-link
                :to="{
                  name: 'consulta.edit',
                  params: { intId: objConsultaExame.aproveitamento.intId },
                }"
                target="_blank"
                :title="
                  'Exame aproveitado da consulta ' +
                  objConsultaExame.aproveitamento.intId +
                  '. Clique para visualizar a consulta'
                "
              >
                <i :class="$utilidade.getIcone('link')" />
              </router-link>
              <ed-collapse :textValue="objConsultaExame.exame.strNome" :textLimit="30" />
            </td>
            <td align="left">
              {{ $utilidade.toDate(objConsultaExame.strDataExame, true) }}
            </td>
            <td align="left">---</td>
            <td align="left">{{ objConsultaExame.status.strNome }}</td>
            <td align="left">---</td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="formData.exames.length && false">
        <tr>
          <th>
            <h7>TOTAL</h7>
          </th>
          <th></th>
          <th></th>
          <th align="right">
            <h6>R$ {{ getTotalExames() }}</h6>
          </th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputDate,
  EdInputText,
  EdInputTextArea,
  EdButton,
  EdInputMoney,
  EdInputAutoComplete,
  EdInputSelect,
  EdModal,
  EdAlert,
  EdCollapse,
  EdForm,
} from "@/components/common/form";

import ExameGeral from "./exameGeral";
import ExameAnexo from "./exameAnexo";
import ExameAudiometria from "./exameAudiometria.vue";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputText,
    EdInputTextArea,
    EdModal,
    EdButton,
    EdInputDate,
    EdInputMoney,
    EdInputAutoComplete,
    EdInputSelect,
    EdAlert,
    EdCollapse,
    EdForm,
    ExameGeral,
    ExameAnexo,
    ExameAudiometria,
  },
  inject: ["bind"],
  provide() {
    return {
      bind: this.bind,
      setTipoStatus: this.setTipoStatus,
      setExame: this.setExame,
    };
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      boolExibirModal: false,
      boolExibirModalEdit: false,
      formDataExame: {
        intId: 0,
        intExameId: null,
        exame: null,
      },
      formDataConsultaExame: {
        intId: 0,
        exame: null,
        parceiro: null,
        intCadastroGeralParceiroId: null,
      },
      strTab: "tab-cadastro",
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {},

    setTipoStatus(item, objConsultaExame) {
      objConsultaExame.status = item;
    },

    setExame(item) {
      this.formDataExame.exame = item;

      if (this.formDataExame.intId) return;

      this.$root.$request
        .get("Cadastro/TabelaPrecoExame", {
          boolBuscarTabelaPreco: true,
          intExameId: item.intId,
          intCadastroGeralUnidadeId: this.formData.intCadastroGeralUnidadeId,
          intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
        })
        .then((objResponse) => {
          console.log("objResponse tabela preco", objResponse);
          this.$root.$dialog.loading(false);

          //TABELA DE PRECO
          let objTabelaPreco = null;
          if (objResponse.status == 200 && objResponse.result) {
            objTabelaPreco = objResponse.result;
          }

          //INSERINDO EXAME
          let objExame = this.$utilidade.arrFindByField(
            this.formData.exames,
            "intExameId",
            this.formDataExame.intExameId
          );

          if (objExame) {
            this.$root.$dialog.warning("Esse exame já foi inserido");
          } else {
            this.formDataExame.floatValorCusto = objTabelaPreco
              ? this.$utilidade.floatToMoney(objTabelaPreco.floatValorCusto)
              : 0;
            this.formDataExame.floatValorVenda = objTabelaPreco
              ? this.$utilidade.floatToMoney(objTabelaPreco.floatValorVenda)
              : 0;
            this.formDataExame.parceiro = objTabelaPreco ? objTabelaPreco.parceiro : null;
            this.formDataExame.intCadastroGeralParceiroId = objTabelaPreco
              ? objTabelaPreco.parceiro.intId
              : null;
          }
        });
    },

    salvarExame() {
      if (this.formDataExame.intId) {
        let intIndexConsultaExame = this.formData.exames.findIndex(
          (c) => c.intId == this.formDataExame.intId
        );

        this.formData.exames[intIndexConsultaExame] = Object.assign(
          this.formDataExame,
          {}
        );

        this.boolExibirModalEdit = false;
      } else {
        //INSERINDO EXAME
        let objExame = this.$utilidade.arrFindByField(
          this.formData.exames,
          "intExameId",
          this.formDataExame.intExameId
        );

        if (objExame) {
          this.$root.$dialog.warning("Esse exame já foi inserido");
        } else {
          this.formDataExame.boolNovoRegistro = 1;
          this.formData.exames.push(this.formDataExame);
          this.boolExibirModalEdit = false;
        }
      }
    },

    onEdit(data) {
      this.boolExibirModalEdit = true;
      this.formDataExame = _.cloneDeep(data);
    },

    onRegister() {
      this.boolExibirModalEdit = true;
      this.formDataExame = Object.assign(
        {
          intId: 0,
          intExameId: null,
          strDataExame: this.$utilidade.toDate(null, true),
          intParametroStatusId: this.bind.arrayAptidao[0].intId,
          intCadastroGeralParceiroId: null,
          exame: null,
        },
        {}
      );
    },

    onDelete(item, index) {
      this.$root.$dialog
        .confirm("Tem certeza que deseja excluir o exame `" + item.exame.strNome + "`?")
        .then((boolExcluir) => {
          this.formData.exames.splice(index, 1);
        });
    },
  },
};
</script>
