<template>
  <div>
    <!--INSERIR NOVO RISCO-->
    <ed-modal v-model="boolExibirModal" width="50%">
      <div slot="title">
        Consulta {{ formData.intId }} :: Inserir Novo Risco
      </div>

      <div class="" slot="content">
        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataRisco.intRiscoId"
            name="intRiscoId"
            item-text="strNome"
            label="Risco"
            route="Cadastro/Risco"
            rules="required"
            @selected="setRisco"
          />

          <div class="col-12 text-right" slot="footer">
            <ed-button
              :iconLeft="$utilidade.getIcone('salvar')"
              color="success"
              title="Salvar"
              label="Inserir"
              @click="inserirRisco"
              :disabled="!formDataRisco.intRiscoId"
            />
          </div>
        </div>
      </div>
    </ed-modal>

    <ed-simple-table
      v-if="formData"
      :config="{ registerName: 'Inserir novo risco' }"
      @register="onRegister"
      disabledSearchRows
    >
      <thead v-if="formData.riscos.length">
        <tr>
          <th width="50">#</th>
          <th>Risco</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(objConsultaRisco, index) in formData.riscos" :key="index">
          <td align="left">
            {{index+1}}
          </td>
          <td align="left">
            <ed-collapse :textValue="objConsultaRisco.risco.strNome" :textLimit="60"/>
          </td>
          <td align="left">
            <ed-button
              :iconLeft="$utilidade.getIcone('deletar')"
              color="error"
              title="Deletar"
              icon
              onlyIcon
              @click="onDelete(objConsultaRisco, index)"
            />
          </td>
        </tr>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputDate,
  EdInputText,
  EdButton,
  EdInputMoney,
  EdInputAutoComplete,
  EdInputSelect,
  EdModal,
  EdCollapse,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputText,
    EdModal,
    EdButton,
    EdInputDate,
    EdInputMoney,
    EdInputAutoComplete,
    EdInputSelect,
    EdCollapse
  },
  inject: ["bind"],
  mounted() {
    this.initialize();
  },
  beforeDestroy() {},
  created() {},
  data() {
    return {
      boolExibirModal: false,
      formDataRisco: {
        intId: 0,
        intRiscoId: null,
        risco: null,
      },
    };
  },
  watch: {},
  methods: {
    initialize() {
    },

    setRisco(item) {
      this.formDataRisco.risco = item;
    },

    inserirRisco() {
      let objExame = this.$utilidade.arrFindByField(
        this.formData.exames,
        "intRiscoId",
        this.formDataRisco.intRiscoId
      );

      if (objExame) {
        this.$root.$dialog.warning("Esse risco já foi inserido");
      } else {
        this.formData.riscos.push({
          intId: 0,
          intConsultaId: this.formData.intId,
          intRiscoId: this.formDataRisco.intRiscoId,
          risco: this.formDataRisco.risco,
        });

        this.boolExibirModal = !this.boolExibirModal;
      }
    },

    onRegister(){
      this.boolExibirModal = !this.boolExibirModal
    },

    onDelete(item, index) {
      this.$root.$dialog
        .confirm(
          "Tem certeza que deseja excluir o risco `" + item.risco.strNome + "`?"
        )
        .then((boolExcluir) => {
          this.formData.riscos.splice(index, 1);
        });
    },
  },
};
</script>
