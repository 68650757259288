var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ed-modal',{attrs:{"width":"70%"},model:{value:(_vm.boolExibirModalEdit),callback:function ($$v) {_vm.boolExibirModalEdit=$$v},expression:"boolExibirModalEdit"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[(_vm.formDataExame.intId)?_c('span',[_vm._v(_vm._s(_vm.formDataExame.exame.strNome)+" :: EDITAR")]):_vm._e(),(!_vm.formDataExame.intId)?_c('span',[_vm._v("INSERIR NOVO EXAME")]):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-tabs',{attrs:{"centered":"","icons-and-text":""},model:{value:(_vm.strTab),callback:function ($$v) {_vm.strTab=$$v},expression:"strTab"}},[_c('v-tab',{attrs:{"href":"#tab-cadastro"}},[_vm._v(" Cadastro Geral "),_c('i',{class:_vm.$utilidade.getIcone('cadastroGeral') + ' fa-2x'})]),(
            _vm.formDataExame &&
            _vm.formDataExame.exame &&
            _vm.formDataExame.exame.intTipoCategoriaId == 1407
          )?_c('v-tab',{attrs:{"href":"#tab-audiometria"}},[_vm._v(" Audiometria "),_c('i',{class:_vm.$utilidade.getIcone('audiometria') + ' fa-2x'})]):_vm._e(),_c('v-tab',{attrs:{"href":"#tab-anexo"}},[_vm._v(" Anexos "),_c('i',{class:_vm.$utilidade.getIcone('upload') + ' fa-2x'})])],1),_c('ed-form',{attrs:{"formData":_vm.formDataExame,"handlerSave":_vm.salvarExame,"disableFixedSave":""}},[(_vm.strTab == 'tab-cadastro')?_c('exame-geral',{key:'tab-geral-' + _vm.$root.$session.versao,attrs:{"formData":_vm.formDataExame}}):_vm._e(),(_vm.strTab == 'tab-anexo')?_c('exame-anexo',{key:'tab-anexo-' + _vm.$root.$session.versao,attrs:{"formData":_vm.formDataExame}}):_vm._e(),(_vm.strTab == 'tab-audiometria')?_c('exame-audiometria',{key:'tab-audiometria-' + _vm.$root.$session.versao,attrs:{"formData":_vm.formDataExame}}):_vm._e()],1)],1)]),(_vm.formData)?_c('ed-simple-table',{attrs:{"config":{ registerName: 'Inserir novo exame' },"disabledSearchRows":""},on:{"register":_vm.onRegister}},[(_vm.formData.exames.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":"50"}},[_vm._v("#")]),_c('th',[_vm._v("Exame")]),_c('th',{attrs:{"width":"180"}},[_vm._v("Data")]),_c('th',{attrs:{"width":"180"}},[_vm._v("Valor")]),_c('th',{attrs:{"width":"180"}},[_vm._v("Resultado")]),_c('th',[_vm._v("Parceiro")]),_c('th',{attrs:{"width":"120"}})])]):_vm._e(),_c('tbody',[_vm._l((_vm.formData.exames),function(objConsultaExame,index){return [(!objConsultaExame.aproveitamento)?_c('tr',{key:index},[_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(index + 1))]),_c('td',{style:(objConsultaExame.boolNovoRegistro
                ? 'color:green'
                : objConsultaExame.status
                ? 'color:' + objConsultaExame.status.strValor.strCor
                : ''),attrs:{"align":"left","title":objConsultaExame.boolNovoRegistro
                ? 'Novo exame inserido'
                : objConsultaExame.status
                ? 'Exame ' + objConsultaExame.status.strNome
                : ''}},[(objConsultaExame.boolNovoRegistro)?_c('i',{class:_vm.$utilidade.getIcone('novoItem'),attrs:{"title":"Novo exame inserido"}}):_vm._e(),_c('ed-collapse',{attrs:{"textValue":objConsultaExame.exame.strNome,"textLimit":30}})],1),_c('td',{attrs:{"align":"left"}},[_c('ed-input-date',{key:'strDataExame-' + index,staticClass:"pl-0 pa-3",attrs:{"name":"strDataExame","rules":"required","hideDetails":""},model:{value:(objConsultaExame.strDataExame),callback:function ($$v) {_vm.$set(objConsultaExame, "strDataExame", $$v)},expression:"objConsultaExame.strDataExame"}})],1),_c('td',{attrs:{"align":"left"}},[_c('ed-input-money',{key:'floatValorVenda-' + index,staticClass:"pl-0 pa-3",attrs:{"name":"floatValorVenda","hideDetails":""},model:{value:(objConsultaExame.floatValorVenda),callback:function ($$v) {_vm.$set(objConsultaExame, "floatValorVenda", $$v)},expression:"objConsultaExame.floatValorVenda"}})],1),_c('td',{attrs:{"align":"left"}},[_c('ed-input-select',{key:'intParametroStatusId-' + index,staticClass:"pl-0 pa-3",attrs:{"name":"intParametroStatusId","hideDetails":"","items":_vm.bind.arrayStatusExame,"iconLeft":objConsultaExame.status
                  ? objConsultaExame.status.strValor.strIcone
                  : null},on:{"selected":function($event){return _vm.setTipoStatus($event, objConsultaExame)}},model:{value:(objConsultaExame.intParametroStatusId),callback:function ($$v) {_vm.$set(objConsultaExame, "intParametroStatusId", $$v)},expression:"objConsultaExame.intParametroStatusId"}})],1),_c('td',{attrs:{"align":"left"}},[_c('ed-input-auto-complete',{staticClass:"pl-0 pa-3",attrs:{"name":"intCadastroGeralParceiroId","item-text":"strNome","route":"Cadastro/CadastroGeral","filters":{
                intTipoCadastroGeralId: 59,
              },"label":"Parceiro","initializeItems":false,"itemList":objConsultaExame.parceiro ? [objConsultaExame.parceiro] : [],"hideDetails":"","clearable":""},model:{value:(objConsultaExame.intCadastroGeralParceiroId),callback:function ($$v) {_vm.$set(objConsultaExame, "intCadastroGeralParceiroId", $$v)},expression:"objConsultaExame.intCadastroGeralParceiroId"}})],1),_c('td',{attrs:{"align":"left"}},[(objConsultaExame.intId)?_c('ed-button',{attrs:{"iconLeft":_vm.$utilidade.getIcone('editar'),"color":"primary","title":"Alterar outras informações do exame","icon":"","onlyIcon":""},on:{"click":function($event){return _vm.onEdit(objConsultaExame, index)}}}):_vm._e(),_c('ed-button',{attrs:{"iconLeft":_vm.$utilidade.getIcone('deletar'),"color":"error","title":"Deletar","icon":"","onlyIcon":""},on:{"click":function($event){return _vm.onDelete(objConsultaExame, index)}}})],1)]):_vm._e()]}),_vm._l((_vm.formData.exames),function(objConsultaExame,index){return [(objConsultaExame.aproveitamento)?_c('tr',{key:index,staticClass:"ed-consulta-aproveitamento"},[_c('td',{attrs:{"align":"left"}}),_c('td',{attrs:{"align":"left"}},[_c('router-link',{attrs:{"to":{
                name: 'consulta.edit',
                params: { intId: objConsultaExame.aproveitamento.intId },
              },"target":"_blank","title":'Exame aproveitado da consulta ' +
                objConsultaExame.aproveitamento.intId +
                '. Clique para visualizar a consulta'}},[_c('i',{class:_vm.$utilidade.getIcone('link')})]),_c('ed-collapse',{attrs:{"textValue":objConsultaExame.exame.strNome,"textLimit":30}})],1),_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.$utilidade.toDate(objConsultaExame.strDataExame, true))+" ")]),_c('td',{attrs:{"align":"left"}},[_vm._v("---")]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(objConsultaExame.status.strNome))]),_c('td',{attrs:{"align":"left"}},[_vm._v("---")])]):_vm._e()]})],2),(_vm.formData.exames.length && false)?_c('tfoot',[_c('tr',[_c('th',[_c('h7',[_vm._v("TOTAL")])],1),_c('th'),_c('th'),_c('th',{attrs:{"align":"right"}},[_c('h6',[_vm._v("R$ "+_vm._s(_vm.getTotalExames()))])]),_c('th'),_c('th')])]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }