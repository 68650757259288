<template>
  <div>
    <ed-modal v-model="bind.boolExibirCadastro" width="50%">
      <div slot="title">
        {{ formData.exame.strNome }}

        <span v-if="formDataAnexo.intId"> :: EDITAR ANEXO</span>
        <span v-if="!formDataAnexo.intId">INSERIR NOVO ANEXO</span>
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataAnexo"
          :handlerSave="salvar"
          disableFixedSave
        >
          <ed-input-file
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataAnexo.arquivo"
            name="intArquivoFotoId"
            label="Arquivo"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataAnexo.strDescricao"
            name="strDescricao"
            label="Descrição"
          />
        </ed-form>
      </div>
    </ed-modal>


    <div class="row">
      <ed-simple-table
        :config="{ registerName: 'Inserir novo anexo' }"
        @register="onRegister"
        disabledSearchRows
      >
        <thead>
          <tr>
            <th width="50">#</th>
            <th>Anexo</th>
            <th>Descrição</th>
            <th width="120"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(objExameAnexo, index) in formData.anexos" :key="index">
            <td align="left">
              {{ index + 1 }}
            </td>
            <td align="left">
              <ed-download :key="'index+'+index" :arquivo="objExameAnexo.arquivo"/>
            </td>
            <td align="left">
              {{ objExameAnexo.strDescricao }}
            </td>
          </tr>
          <tr v-if="!formData.anexos.length">
            <td colspan="4" align="center">
              Nenhum anexo ou documento encontrado
            </td>
          </tr>
        </tbody>
      </ed-simple-table>
    </div>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdInputFile,
  EdInputText,
  EdModal,
  EdDownload,
  EdForm,
} from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputFile,
    EdInputText,
    EdModal,
    EdDownload,
    EdForm,
  },
  inject: [],
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {
      bind: {
        boolExibirCadastro: false,
      },
      formDataAnexo: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    onRegister() {
      this.bind.boolExibirCadastro = true;
      this.formDataAnexo = {
        intId: 0,
        arquivo: null,
        intConsultaExameId: this.formData.intId,
        strDescricao: null,
      };
    },

    onEdit(data) {
      this.bind.boolExibirCadastro = true;
      this.formDataAnexo = Object.assign(data, {});
    },

    salvar() {
      let intIndex = this.formData.anexos.findIndex(
        (c) => c.intId == this.formDataAnexo.intId
      );

      if (intIndex != -1) {
        this.formDataAnexo.anexos[intIndex] = Object.assign(
          this.formDataAnexo,
          {}
        );
      } else {
        this.formData.anexos.push(this.formDataAnexo);
      }
      this.bind.boolExibirCadastro = false;
      this.$forceUpdate();
    },
  },
};
</script>
