<template>
  <div>
    <div class="row ma-0 pa-0">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Dados cadastrais</span>

        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0"
            v-model="formData.intExameId"
            name="intExameId"
            item-text="strNome"
            label="Exame"
            route="Cadastro/Exame"
            rules="required"
            @selected="setExame"
            :initializeItems="false"
            :itemList="this.formData.exame ? [this.formData.exame] : []"
            :disabled="formData.intId ? true : false"
          />

          <ed-input-date
            v-if="formData.intExameId"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pb-0"
            v-model="formData.strDataExame"
            name="strDataExame"
            rules="required"
            label="Data Realização do Exame"
          />

          <ed-input-money
            v-if="formData.intExameId"
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pb-0"
            v-model="formData.floatValorVenda"
            name="floatValorVenda"
            label="Valor do Exame"
          />

          <ed-input-select
            v-if="formData.intExameId"
            label="Resultado"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0 pb-0"
            v-model="formData.intParametroStatusId"
            name="intParametroStatusId"
            :items="bind.arrayStatusExame"
            @selected="setTipoStatus($event, formData)"
          />

          <ed-input-auto-complete
            v-if="formData.intExameId"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0 pb-0"
            v-model="formData.intCadastroGeralParceiroId"
            name="intCadastroGeralParceiroId"
            item-text="strNome"
            route="Cadastro/CadastroGeral"
            :filters="{
              intTipoCadastroGeralId: 59,
            }"
            label="Parceiro"
            :initializeItems="false"
            :itemList="formData.parceiro ? [formData.parceiro] : []"
            clearable
          />

          <ed-input-text-area
            v-if="formData.intExameId"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0 pb-0"
            v-model="formData.strObservacao"
            label="Observações"
            name="strObservacao"
          />
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <campos-personalizado
          :formData="formData"
          :key="'campo-' + $root.$session.versao"
          :intTipoModeloId="1408"
          :parametros="{}"
          @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputDate,
  EdInputText,
  EdInputTextArea,
  EdButton,
  EdInputMoney,
  EdInputAutoComplete,
  EdInputSelect,
} from "@/components/common/form";
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";

export default {
  name: "pageCadastroEmpresa",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdInputDate,
    EdInputText,
    EdInputTextArea,
    EdButton,
    EdInputMoney,
    EdInputAutoComplete,
    EdInputSelect,
    CamposPersonalizado,
  },
  inject: ["bind", "setExame", "setTipoStatus"],
  mounted() {},
  beforeDestroy() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
