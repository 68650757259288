<template>
  <div v-if="formData && bind.boolCarregado">
    <h4 v-if="formData.intId">
      CONSULTA Nº {{ formData.intId }}

      <span v-if="formData.intId && formData.funcionario">
        | {{ formData.funcionario.strNome }}
      </span>
    </h4>

    <div
      style="color: grey"
      v-if="formData.intId && formData.grupo && formData.sub_grupo"
    >
      <v-breadcrumbs :items="getTipoConsulta(formData)" class="pa-0 text-right">
        <template v-slot:divider>
          <v-icon>{{ $utilidade.getIcone("setaDireita") }}</v-icon>
        </template>

        <template v-slot:item="{ item }">
          <v-breadcrumbs-item>
            <v-chip color="green" class="mr-1 mt-1" outlined small>
              {{ item.text }}
            </v-chip>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>

    <ed-alert class="mt-5 mb-5" v-if="formData.intId && formData.boolFinalizado">
      Essa consulta foi finalizada em
      <b>{{ $utilidade.toDate(formData.strDataFinalizacao) }}</b> pelo usuário
      <b>{{ formData.usuario.strNome }}</b>
    </ed-alert>

    <ed-alert
      class="mt-5 mb-5"
      type="error"
      v-else-if="formData.intId && formData.strDataExclusao"
    >
      Essa consulta foi excluída em
      <b>{{ $utilidade.toDate(formData.strDataExclusao) }}</b>
      <span v-if="formData.usuario"
        >pelo usuário <b>{{ formData.usuario.strNome }}</b></span
      >
    </ed-alert>

    <!--IMPRIMIR ASO-->
    <ed-modal v-model="bind.boolExibirModalImpressao" width="80%">
      <div slot="title">Escolha o documento para imprimir</div>

      <div class="" slot="content">
        <v-tabs v-model="strTabImprimir" centered icons-and-text>
          <v-tab href="#tab-imprimir-aso">
            Imprimir Consulta
            <i :class="$utilidade.getIcone('consulta') + ' fa-2x'"></i>
          </v-tab>

          <v-tab href="#tab-imprimir-prontuario">
            Imprimir Prontuário
            <i :class="$utilidade.getIcone('prontuario') + ' fa-2x'"></i>
          </v-tab>

          <v-tab href="#tab-imprimir-exames">
            Imprimir Guia de Exames
            <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
          </v-tab>
        </v-tabs>

        <!--IMPRIMIR ASO-->
        <div class="row" v-if="strTabImprimir == 'tab-imprimir-aso'">
          <div class="col-12">
            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              label="Selecione um modelo"
              name="strTipoVisualizacao"
              v-model="formData.intModeloId"
              :items="bind.arrayModeloAso"
            />
          </div>
          <div class="col-12 text-right">
            <ed-button
              class="mr-1"
              label="Imprimir"
              :iconLeft="$utilidade.getIcone('imprimir')"
              :disabled="!formData.intModeloId"
              @click="imprimirAso()"
              size="100%"
              color="primary"
            />
          </div>
        </div>

        <!--IMPRIMIR PRONTUARIO-->
        <div class="row" v-if="strTabImprimir == 'tab-imprimir-prontuario'">
          <div class="col-12">
            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              label="Selecione um modelo"
              name="strTipoVisualizacao"
              v-model="formData.prontuario[0].intModeloId"
              :items="bind.arrayModeloProntuario"
            />
          </div>
          <div class="col-12 text-right">
            <ed-button
              class="mr-1"
              label="Imprimir"
              :iconLeft="$utilidade.getIcone('imprimir')"
              :disabled="!formData.prontuario[0].intModeloId"
              @click="imprimirProntuario()"
              size="100%"
              color="primary"
            />
          </div>
        </div>

        <!--IMPRIMIR GUIA EXAMES-->
        <div class="row" v-if="strTabImprimir == 'tab-imprimir-exames'">
          <div class="col-12 mt-0 mb-4 text-right">
            <ed-input-select
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              label="Selecione um modelo"
              name="strTipoVisualizacao"
              v-model="formData.intModeloGuiaId"
              :items="bind.arrayModeloGuia"
            />
            <ed-simple-table disabledSearchRows disabledRegisterRows>
              <thead>
                <tr>
                  <th width="50" align="left">#</th>
                  <th align="left">Parceiro</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(objParceiro, index) in arrayParceiro">
                  <tr :key="index">
                    <td align="left">
                      <ed-input-checkbox
                        v-model="objParceiro.boolSelecionado"
                        name="boolFuncionarioNaoIdentificado"
                        inline
                      />
                    </td>
                    <td align="left">
                      {{ objParceiro.strNome }}
                    </td>
                  </tr>
                </template>
                <tr v-if="!arrayParceiro.length">
                  <td colspan="2" align="center">
                    Nenhum parceiro foi encontrado na lista de exames
                  </td>
                </tr>
              </tbody>
            </ed-simple-table>

            <ed-button
              class="mr-1 mt-3"
              label="Imprimir"
              :iconLeft="$utilidade.getIcone('imprimir')"
              @click="imprimirGuiaExame()"
              :disabled="!formData.intModeloGuiaId"
              size="100%"
              color="primary"
            />
          </div>
        </div>
      </div>
    </ed-modal>

    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-consulta">
        Informações da Consulta
        <i :class="$utilidade.getIcone('consulta') + ' fa-2x'"></i>
      </v-tab>
      <v-tab
        href="#tab-exame-previa"
        v-if="
          formData &&
          !formData.intId &&
          formData.grupo &&
          formData.grupo.strChave == 'consultaSubGrupoAso'
        "
        :disabled="!boolExibirPrevia"
      >
        Prévia de Exames a Realizar
        <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-exame" v-if="formData && formData.intId">
        Exames
        <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
      </v-tab>
      <v-tab
        href="#tab-risco"
        v-if="
          formData &&
          formData.intId &&
          formData.grupo &&
          formData.grupo.strChave == 'consultaSubGrupoAso'
        "
      >
        Riscos
        <i :class="$utilidade.getIcone('risco') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-prontuario" v-if="formData && formData.intId">
        Prontuário
        <i :class="$utilidade.getIcone('prontuario') + ' fa-2x'"></i>
      </v-tab>
      <v-tab
        href="#tab-pcmso"
        v-if="
          formData && formData.grupo && formData.grupo.strChave == 'consultaSubGrupoAso'
        "
      >
        Funções no PCMSO
        <i :class="$utilidade.getIcone('pcmso') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-historico">
        Histórico de Consultas
        <i :class="$utilidade.getIcone('historico') + ' fa-2x'"></i>
      </v-tab>
      <v-tab
        href="#tab-esocial"
        v-if="
          formData &&
          !formData.intId &&
          formData.grupo &&
          formData.grupo.strChave == 'consultaSubGrupoAso'
        "
      >
        Histórico de envios no eSocial
        <i :class="$utilidade.getIcone('esocial') + ' fa-2x'"></i>
      </v-tab>
      <!-- <v-tab href="#tab-video" v-if="formData && formData.intId">
        Vídeo Chamada
        <i :class="$utilidade.getIcone('video') + ' fa-2x'"></i>
      </v-tab> -->
    </v-tabs>

    <ed-form
      :formData="formData"
      :block="
        formData.intId && (formData.boolFinalizado || formData.strDataExclusao)
          ? true
          : false
      "
      :handlerSave="
        ['tab-consulta', 'tab-exame', 'tab-risco', 'tab-prontuario'].indexOf(strTab) == -1
          ? null
          : salvar
      "
      v-if="!this.$root.$session.loadingContent"
    >
      <template
        slot="form-footer"
        v-if="
          ['tab-consulta', 'tab-exame', 'tab-risco', 'tab-prontuario'].indexOf(strTab) !=
          -1
        "
      >
        <ed-button
          v-if="formData.intId"
          class="mr-1"
          :label="'Valor dos Exames R$ ' + getTotalExames()"
          :iconLeft="$utilidade.getIcone('dinheiro')"
          size="100%"
          color="orange"
        />

        <ed-button
          v-if="formData.intId"
          class="mr-1"
          label="Imprimir"
          :iconLeft="$utilidade.getIcone('imprimir')"
          @click="bind.boolExibirModalImpressao = true"
          size="100%"
          color="orange"
        />

        <ed-button
          v-if="formData.intId && !formData.boolFinalizado && !formData.strDataExclusao"
          class="mr-1"
          label="Finalizar Atendimento"
          :iconLeft="$utilidade.getIcone('finalizado')"
          @click="finalizar"
          size="100%"
          color="success"
        />
      </template>

      <cadastro-geral
        v-show="
          strTab == 'tab-consulta' &&
          formData.funcionario &&
          formData.funcionario.intId > 0
        "
        :formData="formData"
        :key="'geral-' + $root.$session.versao"
      />

      <previa-exame
        v-if="strTab == 'tab-exame-previa' && boolExibirPrevia"
        :formData="formData"
        :key="'tab-exame-previa-' + $root.$session.versao"
      />

      <pcmso
        v-show="
          strTab == 'tab-pcmso' && formData.funcionario && formData.funcionario.intId > 0
        "
        :formData="formData"
        :intCadastroGeralEmpresaId="formData.intCadastroGeralEmpresaId"
        :intCadastroGeralUnidadeId="formData.intCadastroGeralUnidadeId"
        :intCadastroGeralSetorId="formData.intCadastroGeralSetorId"
        :intTipoDocumentoId="279"
        boolDesabilitarRevisao
        @avisos="onAvisos"
        @apagarAviso="bind.arrayAvisos = []"
        :key="'pcmso-' + $root.$session.versao"
      />

      <historico
        v-show="
          strTab == 'tab-historico' &&
          formData &&
          formData.funcionario &&
          formData.funcionario.intId > 0
        "
        :intFuncionarioId="formData.funcionario.intId"
        :intConsultaAtualId="formData.intId"
        :key="'historico-' + $root.$session.versao"
      />

      <exame
        v-if="strTab == 'tab-exame' && formData && formData.intId"
        :formData="formData"
        :key="'exame-' + $root.$session.versao"
      />

      <risco
        v-if="strTab == 'tab-risco' && formData && formData.intId"
        :formData="formData"
        :key="'risco-' + $root.$session.versao"
      />

      <prontuario
        v-if="strTab == 'tab-prontuario' && formData && formData.intId"
        :formData="formData"
        :key="'prontuario-' + $root.$session.versao"
      />

      <!-- <ed-video
        v-if="strTab == 'tab-video' && formData && formData.intId"
        :consulta="formData"
        :key="'video-' + $root.$session.versao"
      /> -->
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral.vue";
import Pcmso from "./partials/pcmso.vue";
import Historico from "./partials/historico.vue";
import Exame from "./partials/exame.vue";
import Risco from "./partials/risco.vue";
import Prontuario from "./partials/prontuario.vue";
import PreviaExame from "./partials/previaExame.vue";
import EdVideo from "./partials/video.vue";

import {
  EdForm,
  EdButton,
  EdModal,
  EdAlert,
  EdInputSelect,
  EdSimpleTable,
  EdInputCheckbox,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intFuncionarioId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intConsultaId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intParametroSubGrupoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intParametroGrupoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    intMeses: {
      type: [Number, String],
      required: false,
      default: null,
    },
    grupo: {
      type: [Object],
      required: false,
      default: null,
    },
    subGrupo: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    EdAlert,
    EdSimpleTable,
    CadastroGeral,
    Pcmso,
    Historico,
    Exame,
    Risco,
    Prontuario,
    EdButton,
    EdModal,
    EdInputSelect,
    PreviaExame,
    EdInputCheckbox,
    EdVideo
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
    this.initializeParameters();
  },
  created() {},
  data() {
    return {
      strTab: "tab-consulta",
      strTabImprimir: "tab-imprimir-aso",
      bind: {
        objGrupo: null,
        arrayStatus: [],
        arrayStatusExame: [],
        arrayAptidao: [],
        arrayAvisos: [],
        arrayModeloAso: [],
        arrayModeloProntuario: [],
        arrayMeatoscopiaOrelha: [],
        arrayRuidoMascaramento: [],
        arrayDiagnosticoClinico: [],
        arrayDiagnosticoOcupacional: [],
        arrayModeloGuia: [],
        arrayRevisoes: null,
        objFuncionario: null,
        boolCarregado: false,
        floatValorTotalExames: 0,
        boolExibirModalImpressao: false,
        boolExibirModalImpressaoProntuario: false,
      },
      formData: {
        intId: 0,
        intFuncionarioId: null,
        intCadastroGeralUnidadeId: null,
        intCadastroGeralEmpresaId:null,
        intCadastroGeralSetorId: null,
        intFuncaoId: null,
        intParametroGrupoId: null,
        intParametroSubGrupoId: null,
        strDataConsulta: this.$utilidade.toDate(null, true),
      },
    };
  },
  watch: {
    intFuncionarioId() {
      this.initialize();
    },
    intConsultaId() {
      this.initialize();
    },
    intParametroGrupoId() {
      this.initializeParameters();
    },
    intParametroSubGrupoId() {
      this.initializeParameters();
    },
    intMeses() {
      this.initializeParameters();
    },
    "grupo.intId"() {
      this.initializeParameters();
    },
    "subGrupo.intId"() {
      this.initializeParameters();
    },
    "formData.intCadastroGeralEmpresaId"() {},
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {
    boolExibirPrevia() {
      return (
        this.formData.intFuncionarioId &&
        this.formData.intCadastroGeralUnidadeId &&
        this.formData.intCadastroGeralSetorId &&
        this.formData.intParametroGrupoId &&
        this.formData.intParametroSubGrupoId &&
        this.formData.grupo &&
        this.formData.grupo.strChave == "consultaSubGrupoAso"
      );
    },
    arrayParceiro() {
      let array = [];

      if (this.formData.exames) {
        this.formData.exames.forEach((objConsultaExame) => {
          if (objConsultaExame.parceiro) {
            objConsultaExame.parceiro.boolSelecionado = true;

            let intIndexParceiro = array.findIndex(
              (c) => c.intId == objConsultaExame.parceiro.intId
            );

            if (intIndexParceiro == -1) {
              array.push(objConsultaExame.parceiro);
            }
          }
        });
      }

      return array;
    },
  },
  methods: {
    getTipoConsulta(objConsulta) {
      let arrayTipo = [];

      arrayTipo.push({ text: objConsulta.grupo.strNome });
      arrayTipo.push({ text: objConsulta.sub_grupo.strNome });

      if (objConsulta.intMeses) {
        arrayTipo.push({ text: objConsulta.intMeses + " meses" });
      }

      return arrayTipo;
    },

    getTotalExames() {
      let floatValorVenda = 0;

      if (this.formData.exames) {
        this.formData.exames.forEach((element) => {
          floatValorVenda += this.$utilidade.moneyToFloat(element.floatValorVenda);
        });
      }

      return this.$utilidade.floatToMoney(floatValorVenda);
    },

    initializeParameters() {
      this.formData.intParametroGrupoId = this.intParametroGrupoId;
      this.formData.intParametroSubGrupoId = this.intParametroSubGrupoId;
      this.formData.intMeses = this.intMeses;
      this.formData.grupo = this.grupo;
      this.formData.sub_grupo = this.subGrupo;
    },

    finalizar() {
      this.$root.$dialog
        .confirm("Tem certeza que deseja finalizar essa consulta?")
        .then((boolExcluir) => {
          this.formData.boolFinalizar = true;

          this.$root.$dialog.loading(true);

          this.$root.$request
            .post("Consulta/Consulta", this.formData)
            .then((objResponse) => {
              this.$root.$dialog.loading(false);

              if (objResponse.status == 200) {
                this.$root.$message.success("Consulta finalizado com sucesso");
                this.formatarDadosConsulta(objResponse.result);
              }
            });
        });
    },

    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Consulta/Consulta", {
          boolInicializar: true,
          intFuncionarioId: this.intFuncionarioId,
          intConsultaId: this.intConsultaId,
        }),
      ]).then(([objDados, objModelo]) => {
        if (objDados.status == 200 && objDados.result) {
          this.bind.arrayModeloAso = objDados.result.arrayModeloAso.data;
          this.bind.arrayModeloProntuario = objDados.result.arrayModeloProntuario.data;
          this.bind.arrayModeloGuia = objDados.result.arrayModeloGuia.data;

          if (objDados.result.objFuncionario) {
            this.formatarDadosFuncionario(objDados.result.objFuncionario);
          }

          if (objDados.result.objConsulta) {
            if (
              !objDados.result.objConsulta.prontuario ||
              !objDados.result.objConsulta.prontuario.length
            ) {
              objDados.result.objConsulta.prontuario = [
                {
                  intId: 0,
                  intModeloId: null,
                  intConsultaId: objDados.result.objConsulta.intId,
                  strObservacao: null,
                },
              ];
            }

            this.formatarDadosConsulta(objDados.result.objConsulta);
          }

          //this.bind.objGrupo = objDados.result.objConsultaGrupo;
          this.bind.arrayStatus =
            objDados.result.arrayParametro["consultaStatus"]["parametros"];
          this.bind.arrayAptidao =
            objDados.result.arrayParametro["consultaAptidao"]["parametros"];
          this.bind.arrayStatusExame =
            objDados.result.arrayParametro["consultaStatusExame"]["parametros"];
          this.bind.arrayMeatoscopiaOrelha =
            objDados.result.arrayParametro["audiometriaMeatoscopia"]["parametros"];
          this.bind.arrayRuidoMascaramento =
            objDados.result.arrayParametro["audiometriaRuidoMascaramento"]["parametros"];
          this.bind.arrayDiagnosticoClinico =
            objDados.result.arrayParametro["audiometriaDiagnosticoClinicoOrelha"][
              "parametros"
            ];
          this.bind.arrayDiagnosticoOcupacional =
            objDados.result.arrayParametro["audiometriaDiagnosticoOcupacionalOrelha"][
              "parametros"
            ];
        }

        this.$root.$dialog.loading(false);
        this.bind.boolCarregado = true;
      });
    },

    onRevisao(arrayRevisoes) {
      this.bind.arrayRevisoes = arrayRevisoes;
    },

    onAvisos(aviso) {
      this.bind.arrayAvisos.push(aviso);
    },

    imprimirAso() {
      this.$root.$request.open("Consulta/Consulta/get", {
        intConsultaId: this.intConsultaId,
        intModeloId: this.formData.intModeloId,
        boolGerarAso: true,
      });
    },

    imprimirProntuario() {
      this.$root.$request.open("Consulta/ConsultaProntuario/get", {
        intConsultaId: this.intConsultaId,
        intModeloId: this.formData.prontuario[0].intModeloId,
        boolGerarProntuario: true,
      });
    },

    imprimirGuiaExame() {
      let arrayParceiro = [];

      this.arrayParceiro.forEach((element) => {
        if (element.boolSelecionado) {
          arrayParceiro.push(element.intId);
        }
      });

      if (!arrayParceiro.length) {
        this.$root.$dialog.error("Selecione pelo menos um parceiro");
        return;
      }

      this.$root.$request.open("Consulta/Consulta/get", {
        intConsultaId: this.intConsultaId,
        intModeloId: this.formData.intModeloGuiaId,
        arrayParceiro: arrayParceiro,
        boolGerarGuia: true,
      });
    },

    formatarDadosFuncionario(objFuncionario) {
      let formData = Object.assign(objFuncionario, {});

      this.formData.intFuncionarioId = this.intFuncionarioId;
      this.formData.intCadastroGeralEmpresaId = formData.intCadastroGeralEmpresaId;
      this.formData.intCadastroGeralSetorId = formData.intCadastroGeralSetorId;
      this.formData.intCadastroGeralUnidadeId = formData.intCadastroGeralUnidadeId;
      this.formData.intFuncaoId = formData.intFuncaoId;

      this.formData.funcionario = formData;

      if (!this.intConsultaId) {
        this.formData.empresa = formData.empresa;
      }
    },

    formatarDadosConsulta(objConsulta) {
      this.formData = Object.assign(objConsulta, {});

      if (this.bind.arrayModeloGuia.length) {
        this.formData.intModeloGuiaId = this.bind.arrayModeloGuia[0].intId;
      }
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request.post("Consulta/Consulta", this.formData).then((objResponse) => {
        this.$root.$dialog.loading(false);

        if (objResponse.status == 200) {
          this.formatarDadosConsulta(objResponse.result);
          if (this.$route.name != "consulta.edit") {
            this.$router.push({
              name: "consulta.edit",
              params: { intId: objResponse.result.intId },
            });
          }
        }
      });
    },
  },
};
</script>
