<template>
  <div v-if="formData.funcionario">
    <div class="row ma-0 pa-0">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span
          class="form-title"
          style="margin: 0 !important; padding: 0 !important"
          >Funcionário</span
        >
        <router-link
          v-if="this.formData.funcionario.intId > 0"
          class="ml-2"
          :to="{
            name: 'cadastro.funcionario.edit',
            params: { intId: this.formData.funcionario.intId },
          }"
          target="_blank"
        >
          <i :class="$utilidade.getIcone('link')" />
          Ver cadastro do funcionário
        </router-link>

        <div class="row ma-0 pa-0 mb-3">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0 pr-1"
            v-model="formData.funcionario.strCpf"
            label="CPF"
            name="strCpf"
            v-mask="'999.999.999-99'"
            rules="required|cpf"
            :readonly="formData.intId > 0"
          />
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 pa-0"
            v-model="formData.funcionario.strNome"
            label="Nome"
            name="strNome"
            rules="required"
            :readonly="formData.intId > 0"
          />
          <ed-input-date
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pa-0 pr-1"
            v-model="formData.funcionario.strDataNascimento"
            label="Data de Nascimento"
            name="strDataNascimento"
            rules="required"
            :hint="
              $utilidade.calcularIdade(formData.funcionario.strDataNascimento)
            "
            :readonly="formData.intId > 0"
          />

          <!--FUNCAO-->
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 pa-0"
            v-model="formData.intFuncaoId"
            label="Função"
            name="intFuncaoId"
            item-text="strNome"
            route="Cadastro/Funcao"
            :rules="
              formData.funcionario &&
              formData.grupo &&
              formData.grupo.strChave == 'consultaSubGrupoAso'
                ? 'required'
                : ''
            "
            :filters="{}"
            :itemList="arrayFuncao"
            :initializeItems="false"
          />
        </div>

        <span class="form-title">Informações da Consulta da Consulta</span>
        <div class="row row ma-0 pa-0 ma-0">
          <ed-input-date
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-0 pr-1"
            v-model="formData.strDataConsulta"
            label="Data da Consulta"
            name="strDataConsulta"
            rules="required"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-0 pr-0"
            v-model="formData.intParametroAptidaoId"
            label="Resultado da Consulta"
            name="intParametroAptidaoId"
            :items="bind.arrayAptidao"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-0 pr-1 ma-0 pa-0"
            v-model="formData.intConvenioId"
            name="intConvenioId"
            item-text="strNome"
            label="Convênio"
            route="Cadastro/Convenio"
            :itemList="arrayConvenio"
            @selected="setConvenio"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-0 pr-0 pa-0"
            v-model="formData.intConvenioPlanoId"
            label="Plano"
            name="intConvenioPlanoId"
            :disabled="!formData.intConvenioId"
            :items="formData.convenio ? formData.convenio.planos : []"
          />
        </div>
      </div>

      <!--SELECIONAR EMPRESAS-->
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span
          class="form-title"
          v-if="
            formData.funcionario &&
            formData.funcionario.vinculos &&
            formData.funcionario.vinculos.length
          "
          >Empresa</span
        >
        <router-link
          v-if="
            this.formData.intCadastroGeralEmpresaId &&
            formData.funcionario &&
            formData.funcionario.vinculos &&
            formData.funcionario.vinculos.length
          "
          class="ml-2"
          :to="{
            name: 'cadastro.empresa.edit',
            params: { intId: this.formData.intCadastroGeralEmpresaId },
          }"
          target="_blank"
        >
          <i :class="$utilidade.getIcone('link')" />
          Ver cadastro da empresa
        </router-link>

        <div
          class="row ma-0 pa-0"
          v-if="
            formData.funcionario.vinculos &&
            formData.funcionario.vinculos.length
          "
        >
          <!--EMPRESA-->
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 pr-1"
            v-model="formData.intCadastroGeralEmpresaId"
            label="Empresa"
            name="intCadastroGeralEmpresa"
            item-text="strNome"
            route="Cadastro/CadastroGeral"
            :readonly="formData.intId > 0"
            :filters="{
              intTipoCadastroGeralId: 58,
            }"
            :itemList="arrayEmpresa"
            :initializeItems="false"
            :clearable="!formData.intId"
          />

          <!--UNIDADE DE TRABALHO-->
          <ed-input-auto-complete
            v-if="
              formData.funcionario.vinculos &&
              formData.funcionario.vinculos.length
            "
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0 pr-1"
            v-model="formData.intCadastroGeralUnidadeId"
            label="Unidade de Trabalho"
            name="intCadastroGeralUnidadeId"
            item-text="strNome"
            route="Cadastro/CadastroGeralUnidade"
            :rules="formData.intCadastroGeralEmpresaId ? 'required' : ''"
            :readonly="formData.intId > 0"
            :filters="{
              intCadastroGeralEmpresaId:
                this.formData.intCadastroGeralEmpresaId,
              strBusca: null,
            }"
            :itemList="arrayUnidade"
          />

          <!--SETOR-->
          <ed-input-auto-complete
            v-if="
              formData.funcionario.vinculos &&
              formData.funcionario.vinculos.length
            "
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pa-0"
            v-model="formData.intCadastroGeralSetorId"
            label="Setor"
            name="intCadastroGeralSetorId"
            item-text="strNome"
            route="Cadastro/CadastroGeralSetor"
            :rules="formData.intCadastroGeralEmpresaId ? 'required' : ''"
            :readonly="formData.intId > 0"
            :filters="{
              intCadastroGeralEmpresaId:
                this.formData.intCadastroGeralEmpresaId,
            }"
            :itemList="arraySetor"
          />
        </div>

        <span class="form-title">Informações do Médico</span>
        <div class="row ma-0 pa-0">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 ma-0"
            v-model="formData.intProfissionalMedicoExaminadorId"
            label="Médico Examinador"
            name="intProfissionalMedicoExaminadorId"
            item-text="strNome"
            route="Cadastro/Profissional"
            :filters="{
              intTipoProfissionalId: 269,
            }"
            rules=""
            :initializeItems="false"
            :itemList="
              formData.medico_examinador ? [formData.medico_examinador] : []
            "
          />

          <ed-input-auto-complete
            v-if="true"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-0 ma-0 pt-0"
            v-model="formData.intProfissionalMedicoCoordenadorId"
            label="Médico Coordenador"
            name="intProfissionalMedicoCoordenadorId"
            item-text="strNome"
            route="Cadastro/Profissional"
            :filters="{
              intTipoProfissionalId: 269,
            }"
            :initializeItems="false"
            :itemList="
              formData.medico_coordenador ? [formData.medico_coordenador] : []
            "
          />
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <div>
          <campos-personalizado
            :formData="formData"
            :key="'campo-' + $root.$session.versao"
            :intTipoModeloId="317"
            :parametros="{
              intTipoSexoId: formData.funcionario
                ? formData.funcionario.intTipoSexoId
                : null,
            }"
            @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";

import {
  EdSimpleTable,
  EdInputAutoComplete,
  EdInputTipo,
  EdInputRadio,
  EdInputText,
  EdInputDate,
  EdInputSelect,
  EdAlert,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdSimpleTable,
    EdInputAutoComplete,
    EdInputTipo,
    EdInputRadio,
    EdInputSelect,
    EdInputText,
    EdInputDate,
    EdAlert,
    CamposPersonalizado,
  },
  inject: ["bind"],
  provide() {},
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {
    "bind.arrayAvisos"() {},
    "bind.objFuncionario"() {},
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {
    boolAviso() {
      return true;
    },
    arrayEmpresa() {
      let array = [];

      this.formData.funcionario.vinculos.forEach((element) => {
        array.push(element.empresa);
      });

      if(this.formData.funcionario && this.formData.funcionario.empresa){
        array.push(this.formData.funcionario.empresa);
      }

      return array;
    },
    arrayUnidade() {
      let array = [];

      if(this.formData.unidade){
        array.push(this.formData.unidade);
      }

      if(this.formData.funcionario && this.formData.funcionario.unidade){
        array.push(this.formData.funcionario.unidade);
      }

      return array;
    },
    arraySetor() {
      let array = [];

      if(this.formData.setor){
        array.push(this.formData.setor);
      }

      if(this.formData.funcionario && this.formData.funcionario.setor){
        array.push(this.formData.funcionario.setor);
      }

      return array;
    },
    arrayFuncao() {
      let array = [];

      if(this.formData.funcao){
        array.push(this.formData.funcao);
      }

      if(this.formData.funcionario && this.formData.funcionario.funcao){
        array.push(this.formData.funcionario.funcao);
      }

      return array;
    },
    arrayConvenio() {
      let convenios = [];

      if (this.formData.funcionario && this.formData.funcionario.convenios) {
        this.formData.funcionario.convenios.forEach((element) => {
          convenios.push(element.convenio);
        });
      } else if (this.formData.convenio) {
        convenios.push(this.formData.convenio);
      }

      return convenios;
    },
  },
  methods: {
    getEmpresa() {
      let arrayRetorno = [];

      if (this.formData.funcionario) {
        this.formData.funcionario.vinculos.forEach((objValue) => {
          objValue.strNome = objValue.empresa.strNome;

          if (objValue.boolPrincipal) {
            objValue.strNome += " (Principal)";
          }

          arrayRetorno.push(objValue);
        });
      }

      return arrayRetorno;
    },

    setConvenio(obj) {
      if (obj) {
        this.formData.convenio = obj;
      }
    },

    setEmpresa(objValue) {
      if (!objValue || this.formData.intId) return;
      this.formData.empresa = objValue.empresa;

      if (objValue.unidade) {
        this.formData.funcionario.unidade = objValue.unidade;
        this.formData.intCadastroGeralUnidadeId = objValue.unidade.intId;
      }

      if (objValue.setor) {
        this.formData.funcionario.setor = objValue.setor;
        this.formData.intCadastroGeralSetorId = objValue.setor.intId;
      }

      if (objValue.funcao) {
        this.formData.funcionario.funcao = objValue.funcao;
        this.formData.intFuncaoId = objValue.funcao.intId;
      }
    },
  },
};
</script>
